<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table style="width: 100%">
                        <tr>
                            <td class="td-text">
                                合同编号：
                            </td>
                            <td style="width: 400px;">
                                <el-input v-model="searchItem.parentContractNum" size="small" placeholder="请输入合同编号"
                                ></el-input>
                            </td>
                            <td class="td-text">
                                补充协议编号：
                            </td>
                            <td style="width: 400px;">
                                <el-input v-model="searchItem.contractNum" size="small" placeholder="请输入补充协议编号"
                                ></el-input>
                            </td>
                            <td class="td-text">
                                销售人员：
                            </td>
                            <td>
                                <el-select v-model="searchItem.userName" size="small"
                                           style="width: 100%"
                                           filterable
                                           placeholder="请选择销售人员">
                                    <el-option
                                            v-for="item in saleList"
                                            :key="item"
                                            :label="item"
                                            :value="item">
                                    </el-option>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-text" style="padding-top: 10px">
                                联系电话：
                            </td>
                            <td style="padding-top: 10px">
                                <el-input v-model="searchItem.userTel" size="small" placeholder="请输入联系电话"
                                ></el-input>
                            </td>
                            <td class="td-text" style="padding-top: 10px">
                                申请时间：
                            </td>
                            <td style="width: 400px;padding-top:10px">
                                <el-date-picker
                                        v-model="searchItem.startDate"
                                        type="date"
                                        size="small"
                                        placeholder="选择日期时间"
                                        style="width: 47%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                                至
                                <el-date-picker
                                        v-model="searchItem.endDate"
                                        type="date"
                                        placeholder="选择日期时间"
                                        size="small"
                                        style="width: 47%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </td>
                            <td class="td-text" style="padding-top: 10px">
                                公司名称：
                            </td>
                            <td style="width: 400px;padding-top: 10px">
                                <template>
                                    <el-input v-model="searchItem.companyName" size="small" class="handle-input"
                                              placeholder="请选择公司名称">
                                    </el-input>
                                </template>
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: right;padding-top: 10px">项目名称：</td>
                            <td style="padding-top: 10px">
                                <el-input v-model="searchItem.projectName" size="small" placeholder="请输入项目名称"></el-input>
                            </td>
                            <td></td>
                            <td></td>
                            <td style="padding-top: 10px;text-align: center" colspan="2">
                                <!--<el-button type="primary" icon="el-icon-download" size="small" @click="exportExcel">导出为Excel文件</el-button-->
                                <el-button type="primary" icon="el-icon-search" size="small"
                                           @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" style="margin-left: 25%" icon="el-icon-refresh-right"
                                           size="small" @click="resetBtn">重置
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
                            <el-table-column prop="contractNum" label="补充协议编号" width="151" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="parentContractNum" label="合同编号" width="151" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="contractType" label="合同类型" width="120" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.contractType == 0">销售合同</span>
                                    <span v-else-if="scope.row.contractType == 1" >技术服务合同</span>
                                    <span v-else-if="scope.row.contractType == 2" >部件买卖合同</span>
                                    <span v-else-if="scope.row.contractType == 3" >售后合同</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="userName" label="销售人员" width="100" align="center"></el-table-column>
                            <el-table-column prop="userTel" label="联系电话" width="110" align="center"></el-table-column>
                            <el-table-column prop="towerCount" label="塔机" width="50" align="center"></el-table-column>
                            <el-table-column prop="lifterCount" label="升降机" width="70" align="center"></el-table-column>
                            <el-table-column label="增加账款(元)" width="120" align="right" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.contractReceivables == null">0</span>
                                    <span v-else-if="scope.row.contractReceivables == 0">0</span>
                                    <span v-else>{{(scope.row.contractReceivables/100).toFixed(2)}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="companyName" label="公司名称" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="companyType" label="公司类型" width="100" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.companyType == 0">建机厂</span>
                                    <span v-else-if="scope.row.companyType == 1">国企</span>
                                    <span v-else-if="scope.row.companyType == 2">租赁商</span>
                                    <span v-else-if="scope.row.companyType == 3">代理商</span>
                                    <span v-else-if="scope.row.companyType == 4">集团公司</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="projectName" label="项目名称" width="140" show-overflow-tooltip
                                             align="left">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.projectName == ''||scope.row.projectName==undefined">无</span>
                                    <span v-else>{{scope.row.projectName}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="auditState" label="审批状态" width="80" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.auditState == -1" style="color: red">已驳回</span>
                                    <span v-else-if="scope.row.auditState == -2" style="color: red">商务驳回</span>
                                    <span v-else-if="scope.row.auditState == 0">商务编辑</span>
                                    <span v-else-if="scope.row.auditState == 5" style="color: #F0AD4E">待发货</span>
                                    <span v-else-if="scope.row.auditState == 10" style="color: green">通过</span>
                                    <span v-else style="color: blue">审批中</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" label="申请时间" width="100" :formatter="dateFormat"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="id" label="操作" width="170" align="center">
                                <template slot-scope="scope">
                                    <div class="opertion" v-if="isSalesman == 'false'">
                                        <i class="iconfont iconxiangqing" title="查看详情"
                                           @click="showSupplement(scope.row.id)"></i>
                                        <i class="iconfont iconbianji" title="编辑补充协议"
                                           v-if="scope.row.auditState == 0 && hasAuthority('supple_approval_update')
                                            || scope.row.auditState == -1 && hasAuthority('supple_approval_update')"
                                           @click="editSupplement(scope.row.id)"></i>
                                        <i class="iconfont icontongguo" title="提交申请"
                                           v-if="scope.row.auditState == 0 && hasAuthority('supple_approval_audit')
                                           || scope.row.auditState == -1 && hasAuthority('supple_approval_audit')"
                                           @click="passSupplement(scope.row.id)"></i>
                                        <i class="iconfont iconbohui" title="驳回"
                                           v-if="scope.row.auditState == 0 && hasAuthority('supple_approval_audit')
                                           || scope.row.auditState == -1 && hasAuthority('supple_approval_audit')"
                                           @click="refuseSupplement(scope.row.id)"></i>
                                    </div>
                                    <div v-else>
                                        <i class="iconfont iconxiangqing" title="查看详情"
                                           @click="showSupplement(scope.row.id)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>

        <div class="data-dialog">
            <el-dialog
                    :title="saveTitle"
                    :visible.sync="dialogVisible"
                    width="70%"
                    style="margin-top: -90px"
                    :close-on-click-modal="isClose"
                    center>
                <div class="dialog-body">
                    <div class="basic-info">
                        <div class="text-title">
                            基础信息
                        </div>
                        <div>
                            <el-form :model="supplementForm" :rules="rules" ref="supplementForm" label-width="110px"
                                     class="demo-ruleForm">
                                <el-form-item size="small" label="协议名称:" prop="contractName">
                                    <el-input size="small" v-model="supplementForm.contractName"></el-input>
                                </el-form-item>
                                <el-row>
                                    <el-col :span="10" style="width: 50%">
                                        <el-form-item size="small" label="协议号:" prop="contractNum">
                                            <el-input size="small"
                                                      v-model="supplementForm.contractNum"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="10" style="width: 50%">
                                        <el-form-item size="small" label="公司名称:" prop="companyName">
                                            <el-input size="small" disabled
                                                      v-model="supplementForm.companyName"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="10" style="width: 50%">
                                        <el-form-item size="small" label="公司类型:" prop="companyType">
                                            <el-select v-model="supplementForm.companyType" style="width: 100%" disabled
                                                       placeholder="请选择公司类型">
                                                <el-option
                                                        v-for="item in unitTypes"
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="10" style="width: 50%">
                                        <el-form-item size="small" label="设备类型:" prop="isTaxpayer"
                                                      style="text-align: center">
                                            <span>{{supplementForm.deviceType == 0?"普通型":"加强型"}}</span>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="10" style="width: 50%">
                                        <el-form-item size="small" label="塔机数量:" prop="towerCount">
                                            <el-input size="small" disabled v-model.number="supplementForm.towerCount"
                                                      autocomplete="off"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="10" style="width: 50%">
                                        <el-form-item size="small" label="升降机数量:" prop="lifterCount">
                                            <el-input size="small" disabled v-model.number="supplementForm.lifterCount"
                                                      autocomplete="off"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="10" style="width: 50%">
                                        <el-form-item size="small" label="是否安装:" prop="install"
                                                      style="text-align: center">
                                            <span>{{supplementForm.isInstalled == 1?"是":"否"}}</span>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="10" style="width: 50%">
                                        <el-form-item size="small" label="新增账款(元):" prop="contractReceivables">
                                            <el-input size="small" v-model="supplementForm.contractReceivables" type="tel"
                                                      onKeypress="return (/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
                                                      autocomplete="off"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="10" style="width: 50%">
                                        <el-form-item size="small" label="开始日期:" prop="startDate">
                                            <el-date-picker
                                                    v-model="supplementForm.startDate"
                                                    type="date"
                                                    style="width: 100%"
                                                    placeholder="选择日期时间"
                                                    value-format="yyyy-MM-dd">
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="10" style="width: 50%">
                                        <el-form-item size="small" style="width: 100%" label="截止日期:" prop="endDate">
                                            <el-date-picker
                                                    v-model="supplementForm.endDate"
                                                    type="date"
                                                    style="width: 100%"
                                                    placeholder="选择日期时间"
                                                    value-format="yyyy-MM-dd">
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="10" style="width: 50%">
                                        <el-form-item size="small" label="项目名称:" prop="install"
                                                      style="text-align: center">
                                            <span>{{supplementForm.projectName}}</span>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-form-item size="small" label="收费政策:" prop="chargePolicy">
                                    <el-input type="textarea" size="small" :rows="3"
                                              v-model="supplementForm.chargePolicy"></el-input>
                                </el-form-item>
                                <el-form-item size="small" label="特殊政策及发货说明:" prop="specialPolicy">
                                    <el-input type="textarea" size="small" :rows="3"
                                              v-model="supplementForm.specialPolicy"></el-input>
                                </el-form-item>
                                <el-form-item size="small" label="备注:" prop="remark">
                                    <el-input type="textarea" size="small" :rows="3"
                                              v-model="supplementForm.remark"></el-input>
                                </el-form-item>
                            </el-form>
                            <table style="width: 100%;padding-top: 10px">
                                <tr>
                                    <td style="width: 94px">功能需求：</td>
                                    <td colspan="5" style="padding-top: 10px">
                                        <div class="td-body">
                                            <table cellpadding="0" cellspacing="0" style="width: 100%">
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        序号
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.index}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;text-overflow:ellipsis;padding: 3px; text-align: center">
                                                        塔机
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        升降机
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in lifterItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in lifterItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        特殊型号
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in specialItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in specialItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <template>
                                <el-table
                                        :data="basicTable"
                                        border
                                        style="width: 100%"
                                        :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                        :cell-style="{padding:'0',height: '41px'}">
                                    <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                                    <el-table-column prop="deviceCategory" label="设备类型" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.deviceCategory == 1">升降机</span>
                                            <span v-else>塔机</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="sizeModel" label="规格型号" align="center"></el-table-column>
                                    <el-table-column prop="siteNum" label="现场编码" align="center"></el-table-column>
                                    <el-table-column prop="stringDiameter" label="钢丝绳直径(mm)"
                                                     align="center"></el-table-column>
                                    <el-table-column prop="armLength" label="前臂长度(m)" align="center"></el-table-column>
                                    <el-table-column prop="maxLoad" label="最大载重(T)" align="center"></el-table-column>
                                    <el-table-column prop="deviceType" label="类型" align="center"></el-table-column>
                                    <el-table-column prop="isNeedCard" label="是否需要视频流量卡" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.isNeedCard == 1">是</span>
                                            <span v-else>否</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="isNeedModel" align="center" label="是否需要现场调试多塔防碰撞功能">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.isNeedModel == 1">是</span>
                                            <span v-else>否</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </div>
                    </div>
                    <div class="upload-files">
                        <div class="text-title">
                            附件信息
                        </div>
                        <div class="file-type">
                            <div style="width: 8%;padding: 10px">
                                设备型号清单:
                            </div>
                            <div style="width: 6%">
                                <el-tooltip class="item" effect="dark" content="点此上传设备信息清单" placement="top-start">
                                    <el-button type="text" v-if="isPass == 0||isPass == -1" @click="uploadList(0)">
                                        点此上传
                                    </el-button>
                                    <el-button type="text" v-else disabled>点此上传</el-button>
                                </el-tooltip>
                            </div>
                            <div style="width: 87%;padding: 12px">
                                <span>{{supplementForm.deviceListUrl != null?supplementForm.deviceListUrl.split('/').slice(-1) + '':''}}</span>
                            </div>
                        </div>
                        <div class="file-type">
                            <div style="width: 8%;padding: 10px">
                                项目信息清单:
                            </div>
                            <div style="width: 6%">
                                <el-tooltip class="item" effect="dark" content="点此上传项目信息清单" placement="top-start">
                                    <el-button type="text" v-if="isPass == 0||isPass == -1" @click="uploadList(1)">
                                        点此上传
                                    </el-button>
                                    <el-button type="text" v-else disabled>点此上传</el-button>
                                </el-tooltip>
                            </div>
                            <div style="width: 87%;padding: 12px">
                                <span>{{supplementForm.projectMsgUrl != null?supplementForm.projectMsgUrl.split('/').slice(-1) + '':''}}</span>
                            </div>
                        </div>
                        <div class="file-type">
                            <div style="width: 8%;padding: 10px">
                                其它附件:
                            </div>
                            <div style="width: 6%">
                                <el-tooltip class="item" effect="dark" content="点此上传其它附件" placement="top-start">
                                    <el-button type="text" v-if="isPass == 0||isPass == -1" @click="uploadList(2)">
                                        点此上传
                                    </el-button>
                                    <el-button type="text" v-else disabled>点此上传</el-button>
                                </el-tooltip>
                            </div>
                            <div style="width: 87%;padding: 12px">
                                <span>{{supplementForm.otherAttachUrl != null?supplementForm.otherAttachUrl.split('/').slice(-1) + '':''}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="approval-table">
                        <div class="text-title">
                            审批历史
                        </div>
                        <template>
                            <el-table
                                    :data="approvalRecord"
                                    border
                                    style="width: 100%">
                                <el-table-column prop="depName" label="部门"></el-table-column>
                                <el-table-column prop="userName" label="审批人"></el-table-column>
                                <el-table-column prop="auditRemark" label="意见">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.auditRemark == null">无</span>
                                        <span v-else>{{scope.row.auditRemark}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="时间" :formatter="dateFormat">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.createTime == null">无</span>
                                        <span v-else>{{scope.row.createTime}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="状态">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.isPass == 0 && scope.row.isEffective == 1"
                                              style="color: red">驳回</span>
                                        <span v-else-if="scope.row.isPass == 0 && scope.row.isEffective == 0"
                                              style="color: #F08080">驳回</span>
                                        <span v-else-if="scope.row.isPass == 1 && scope.row.isEffective == 1"
                                              style="color: green">通过</span>
                                        <span v-else-if="scope.row.isPass == 1 && scope.row.isEffective == 0"
                                              style="color: #98FB98">通过</span>
                                        <span v-else style="color: blue">未审批</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancel('supplementForm')">取 消</el-button>
                    <el-button type="primary" :loading="isDisabled"
                               @click="saveSupplement('supplementForm')">保 存</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="shipping-dialog">
            <el-dialog
                    title="出库单据详情"
                    :visible.sync="shippingVisible"
                    width="70%"
                    :close-on-click-modal="false"
                    center>
                <el-table
                        :data="shippingData"
                        style="width: 100%;height: 100%;" :border="true"
                        :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                        :cell-style="{padding:'0',height: '41px'}">
                    <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
                    <el-table-column prop="outListNum" label="出库单据" width="150" align="center"></el-table-column>
                    <el-table-column prop="createTime" label="出库时间" width="120" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="sendUser" label="操作人员" width="140" align="center"></el-table-column>
                    <el-table-column prop="sendCount" label="发货数量" width="110" align="center"></el-table-column>
                    <el-table-column label="物料详情" width="140" show-overflow-tooltip align="center">
                        <template slot-scope="scope">
                            <span>SIM卡{{scope.row.sendCount}}张，IMEI型号{{scope.row.sendCount}}个</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="receivingUser" label="发货对象" width="100" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="receivingUserTel" label="收件人电话" width="100" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="receivingAddress" label="发货地址" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <!--  <el-table-column label="发货状态" width="100"  align="center">
                          <template slot-scope="scope">
                              <span v-if="scope.row.status == true" style="color: green">已发货</span>
                              <span v-if="scope.row.status != true" style="color: red">未发货</span>
                          </template>
                      </el-table-column>  -->
                    <el-table-column prop="remark" label="备注" align="center" show-overflow-tooltip></el-table-column>
                </el-table>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="shippingVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="information">
            <el-dialog
                    :title="showTitle"
                    :visible.sync="infoVisible"
                    style="margin-top: -90px"
                    width="70%"
                    :close-on-click-modal="false"
                    center>
                <div class="dialog-body">
                    <div class="basic-info">
                        <div class="text-title">
                            基础信息
                        </div>
                        <div>
                            <table style="text-align: left;width: 100%;">
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        补充编号：
                                    </td>
                                    <td style="width: 300px;">
                                        {{supplementDisplay.contractNum}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">合同号：</td>
                                    <td style="width: 170px">
                                        {{supplementDisplay.parentContractNum}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">公司名称：</td>
                                    <td style="width: 300px">
                                        {{supplementDisplay.companyName}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">新增账款(元)：</td>
                                    <td style="width: 300px">
                                        {{supplementDisplay.contractReceivables==0?0:(supplementDisplay.contractReceivables/100).toFixed(2)}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">合同类型：</td>
                                    <td style="width: 170px">
                                        {{supplementDisplay.contractType == 0 ? '销售合同':supplementDisplay.contractType == 1 ?'技术服务合同':supplementDisplay.contractType == 2 ?'部件买卖合同':supplementDisplay.contractType == 3 ?"售后合同":"未知合同"}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">公司类型：</td>
                                    <td style="width: 300px">
                                        {{supplementDisplay.companyType == 0?"建机厂":(supplementDisplay.companyType ==
                                        1?"国企":(supplementDisplay.companyType == 2?"租赁商":(supplementDisplay.companyType == 3?"代理商":"集团公司")))}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">塔机数量：</td>
                                    <td style="width: 170px">
                                        {{supplementDisplay.towerCount}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">
                                        升降机数量：
                                    </td>
                                    <td style="width: 300px;">
                                        {{supplementDisplay.lifterCount}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">是否安装：</td>
                                    <td style="width: 170px">
                                        {{supplementDisplay.isInstalled == 1?'是':'否'}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">项目名称：</td>
                                    <td style="width: 170px">
                                        {{supplementDisplay.projectName}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">服务周期：</td>
                                    <td style="width: 170px">
                                        <span>{{supplementDisplay.startDate}}~{{supplementDisplay.endDate}}</span>
                                    </td>
                                    <td style="padding: 10px;width: 120px">服务周期：</td>
                                    <td style="width: 170px">
                                        <span>{{supplementDisplay.deviceType == 0?"普通型":"加强型"}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        收费政策：
                                    </td>
                                    <td style="width: 300px;" colspan="5">
                                        {{supplementDisplay.chargePolicy}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        特殊政策及发货说明：
                                    </td>
                                    <td style="width: 300px;" colspan="5">
                                        {{supplementDisplay.specialPolicy}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        备注：
                                    </td>
                                    <td style="width: 300px;" colspan="5">
                                        {{supplementDisplay.remark}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>功能需求：</td>
                                    <td colspan="5" style="padding: 10px 0">
                                        <div class="td-body">
                                            <table cellpadding="0" cellspacing="0" style="width: 100%">
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        序号
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.index}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;text-overflow:ellipsis;padding: 3px; text-align: center">
                                                        塔机
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        升降机
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in lifterItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in lifterItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        特殊型号
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in specialItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in specialItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <template>
                                <el-table
                                        :data="basicTable"
                                        border
                                        style="width: 100%"
                                        :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                        :cell-style="{padding:'0',height: '41px'}">
                                    <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                                    <el-table-column prop="deviceCategory" label="设备类型" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.deviceCategory == 1">升降机</span>
                                            <span v-else>塔机</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="sizeModel" label="规格型号" align="center"></el-table-column>
                                    <el-table-column prop="siteNum" label="现场编码" align="center"></el-table-column>
                                    <el-table-column prop="stringDiameter" label="钢丝绳直径(mm)"
                                                     align="center"></el-table-column>
                                    <el-table-column prop="armLength" label="前臂长度(m)" align="center"></el-table-column>
                                    <el-table-column prop="rearArmLength" label="后臂长度(m)" align="center"></el-table-column>
                                    <el-table-column prop="maxLoad" label="最大载重(T)" align="center"></el-table-column>
                                    <el-table-column prop="deviceType" label="类型" align="center"></el-table-column>
                                    <el-table-column prop="isNeedCard" label="是否需要视频流量卡" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.isNeedCard == 1">是</span>
                                            <span v-else>否</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="isNeedModel" align="center" label="是否需要现场调试多塔防碰撞功能">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.isNeedModel == 1">是</span>
                                            <span v-else>否</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </div>
                    </div>
                    <div class="approval-table">
                        <div class="text-title">
                            审批历史
                        </div>
                        <template>
                            <el-table
                                    :data="approvalRecord"
                                    border
                                    style="width: 100%">
                                <el-table-column prop="depName" label="部门"></el-table-column>
                                <el-table-column prop="userName" label="审批人"></el-table-column>
                                <el-table-column prop="auditRemark" label="意见">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.auditRemark == null">无</span>
                                        <span v-else>{{scope.row.auditRemark}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="时间" :formatter="dateFormat">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.createTime == null">无</span>
                                        <span v-else>{{scope.row.createTime}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="状态">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.isPass == 0 && scope.row.isEffective == 1"
                                              style="color: red">驳回</span>
                                        <span v-else-if="scope.row.isPass == 0 && scope.row.isEffective == 0"
                                              style="color: #F08080">驳回</span>
                                        <span v-else-if="scope.row.isPass == 1 && scope.row.isEffective == 1"
                                              style="color: green">通过</span>
                                        <span v-else-if="scope.row.isPass == 1 && scope.row.isEffective == 0"
                                              style="color: #98FB98">通过</span>
                                        <span v-else style="color: blue">未审批</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </div>
                    <div class="down-file">
                        <div class="file-type">
                            <div style="width: 8%;padding: 10px">
                                设备型号清单:
                            </div>
                            <div style="width: 87%;padding: 12px">
                                <a :href="urlFilePath + 'erp' + supplementDisplay.deviceListUrl">
                                    {{supplementDisplay.deviceListUrl==null?'':supplementDisplay.deviceListUrl.split("/").slice(-1)+
                                    ''}}
                                </a>
                            </div>
                        </div>
                        <div class="file-type">
                            <div style="width: 8%;padding: 10px">
                                项目信息清单:
                            </div>
                            <div style="width: 87%;padding: 12px">
                                <a :href="urlFilePath + 'erp' + supplementDisplay.projectMsgUrl">
                                    {{supplementDisplay.projectMsgUrl==null?'':supplementDisplay.projectMsgUrl.split("/").slice(-1)+
                                    ''}}
                                </a>
                            </div>
                        </div>
                        <div class="file-type">
                            <div style="width: 8%;padding: 10px">
                                其它附件:
                            </div>
                            <div style="width: 87%;padding: 12px">
                                <a :href="urlFilePath + 'erp' + supplementDisplay.otherAttachUrl">
                                    {{supplementDisplay.otherAttachUrl==null?'':supplementDisplay.otherAttachUrl.split("/").slice(-1)+
                                    ''}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="infoVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="export">
            <el-dialog
                    title="审批记录"
                    :visible.sync="exportDialogVisible"
                    width="52.6%" center>
                <download-excel
                        class="export-excel-wrapper"
                        :data="json_data"
                        :fields="json_fields"
                        :name="fileName">
                    <el-table
                            :data="tableData"
                            max-height="400px"
                            size="small"
                            row-class-name="row"
                            cell-class-name="column"
                            :highlight-current-row="true"
                            fit>
                        <el-table-column
                                v-for="(item, index) in jsonFields"
                                :key="index"
                                :prop="item.prop"
                                :width="item.width"
                                :label="item.label" show-overflow-tooltip>
                        </el-table-column>
                    </el-table>
                    <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
                    <el-button type="primary" size="small" style="margin-left: 48%;margin-top: 1%"
                               @click="exportDialogVisible=false">导出数据
                    </el-button>
                </download-excel>
            </el-dialog>
        </div>
        <div class="upload">
            <el-dialog
                    :title="uploadTitle"
                    :visible.sync="fileDialog"
                    width="410px"
                    :close-on-click-modal="false"
                    center>
                <el-upload
                        class="upload-demo"
                        drag
                        ref="upload"
                        style="width: 100%"
                        :action='url'
                        :before-upload="handleBeforeUpload"
                        :on-success="getSuccessData"
                        :on-error="getErrorData"
                        multiple>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">{{tipMessage}}</div>
                </el-upload>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelFile">取 消</el-button>
                    <el-button type="primary" @click="submitFile">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import supplement from '../../../public/json/supple.json'
    import shipping from '../../../public/json/shipping.json'
    import funOptions from '../../../public/json/funOptions.json'
    import amount from '../../../public/json/amount.json'
    import approval from '../../../public/json/approval.json'
    import basicData from '../../../public/json/basicData.json'

    var pathURL = process.env.VUE_APP_CURENV;

    export default {
        data() {
            return {
                pageList: [14, 20, 30, 50],
                tableHeight: '',
                lineHeight: '',
                isDisabled: false,
                uploadTitle: '',

                url: '',
                urlFilePath: pathURL,
                fileDialog: false,
                filePath1: '',
                filePath2: '',
                filePath3: '',
                isPass: 0,
                showTitle: '',
                jsonFields: [
                    /* {label: '', width: '40', prop: 'id'},*/
                    {label: '合同编号', width: '120', prop: 'number'},
                    {label: '合同类型', width: '120', prop: 'supplementType'},
                    {label: '销售人员', width: '120', prop: 'salesman'},
                    {label: '应收账款(元)', width: '120', prop: 'amount'},
                    {label: '公司名称', width: '120', prop: 'companyName'},
                    {label: '公司类型', width: '120', prop: 'unitType'},
                    {label: '当前状态', width: '120', prop: 'nowStatus'},
                    {label: '备注', width: '120', prop: 'remark'},
                ],
                fileName: '',
                fields: [],
                values: [],
                json_fields: {},
                json_data: [],
                json_meta: [
                    [
                        {
                            " key ": " charset ",
                            " value ": " utf- 8 "
                        }
                    ]
                ],
                exportDialogVisible: false,
                basicTable: [],
                collectionRecord: [],
                approvalRecord: [],
                checkAll: false,
                functions: [],
                funNameList: [],
                isIndeterminate: true,
                dialogVisibles: false,
                shippingVisible: false,
                infoVisible: false,
                multipleSelection: [],
                towerItems: [],
                lifterItems: [],
                specialItems: [],
                delList: [],
                saveTitle: '',
                saleList: [],
                // totalPage:0,
                searchItem: {
                    contractNum: '',
                    parentContractNum: '',
                    userName: '',
                    endDate: '',
                    startDate: '',
                    companyName: '',
                    projectName: '',
                    pageSize: 14,
                    pageIndex: 1,
                },
                totalPage: 30,
                dialogVisible: false,
                option: {
                    pageIndex: '',
                    pageSize: 14,
                    currentPage: 2
                },
                supplementTypes: [
                    {
                        label: '技术服务合同',
                        value: 0
                    },
                    {
                        label: '销售合同',
                        value: 1
                    },
                    {
                        label: '测试合同',
                        value: 2
                    },
                ],
                unitTypes: [
                    {
                        label: '建机厂',
                        value: 0
                    },
                    {
                        label: '国企',
                        value: 1
                    },
                    {
                        label: '租赁商',
                        value: 2
                    },
                    {
                        label: '代理商',
                        value: 3
                    },
                    {
                        label: '集团公司',
                        value: 4
                    },
                ],
                UpDialogVisible: false,
                tableData: [],
                shippingData: [],
                supplementForm: {
                    createTime: null,
                    chargePolicy: null,
                    companyName: null,
                    companyType: null,
                    contractName: '',
                    projectMsgUrl: '',
                    deviceListUrl: '',
                    otherAttachUrl: '',
                    contractNum: "",
                    isInstalled: '',
                    startDate: '',
                    endDate: '',
                    contractType: 1,
                    id: "",
                    remark: '',
                    lifterCount: 2,
                    projectName: null,
                    contractReceivables: 0,
                    towerCount: 2,
                    updateUserName: "",
                    other: '',
                    deviceType: ''
                },
                contractId: '',
                uploadType: '',
                supplementDisplay: {
                    createTime: null,
                    chargePolicy: null,
                    parentContractNum: '',
                    startDate: '',
                    endDate: '',
                    deviceType: '',
                    companyName: null,
                    companyType: null,
                    contractNum: "",
                    specialPolicy: '',
                    isInstalled: '',
                    contractName: '',
                    contractType: 1,
                    id: "",
                    lifterCount: 2,
                    projectName: null,
                    contractReceivables: 0,
                    towerCount: 2,
                    updateUserName: "",
                },
                rules: {
                    chargePolicy: [{required: true, message: '补充协议名称不能为空', trigger: 'blur'}],
                    contractName: [{required: true, message: '补充协议名称不能为空', trigger: 'blur'}],
                    specialPolicy: [{required: true, message: '补充协议号不能为空', trigger: 'blur'}],
                    contractReceivables: [{required: true, message: '新增金额不能为空', trigger: 'blur'}],
                },
                isClose: false,
                isSalesman: '',
                tipMessage: '',
                contractFile: {
                    id: '',
                    projectMsgUrl: '',
                    deviceListUrl: '',
                    otherAttachUrl: ''
                }
            }
        },
        methods: {
            dateFormat(row, column) {
                let data = row[column.property]
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date
            },
            resetBtn() {
                this.searchItem = {
                    contractNum: '',
                    number: '',
                    userName: '',
                    endDate: '',
                    startDate: '',
                    projectName: '',
                    companyName: '',
                    pageSize: 14,
                    pageIndex: 1,
                }
                this.pageChange(1);
            },
            passSupplement(id) {
                this.$confirm('你确定要提交已修改的补充协议吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.replenishAudit({id: id, isPass: 1}).then(
                        res => {
                            if (res.code == 200) {
                                this.pageChange("refresh")
                                this.$message.success(res.message)
                            } else {
                                this.$message.error(res.message)
                            }
                        }
                    )
                }).catch(() => {
                })
            },
            refuseSupplement(id) {
                this.$confirm('你确定要驳回此补充协议吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.replenishAudit({id: id, isPass: 0}).then(
                        res => {
                            if (res.code == 200) {
                                this.$message.success(res.message);
                                this.pageChange("refresh")
                            } else {
                                this.$message.error(res.message)
                            }
                        }
                    )
                }).catch(() => {
                })
            },
            editSupplement(id) {
                this.towerItems = [];
                this.lifterItems = [];
                this.$api.getSupplementById({id}).then(res => {
                    if (res.code = 200) {
                        this.dialogVisible = true;
                        this.supplementForm = {
                            createTime: res.data.createTime,
                            isInstalled: res.data.isInstalled,
                            chargePolicy: res.data.chargePolicy,
                            specialPolicy: res.data.specialPolicy,
                            parentContractNum: res.data.parentContractNum,
                            companyName: res.data.companyName,
                            companyType: res.data.companyType,
                            startDate: res.data.startDate,
                            endDate: res.data.endDate,
                            contractNum: res.data.contractNum,
                            contractType: res.data.contractType,
                            contractName: res.data.contractName,
                            id: res.data.id,
                            remark: res.data.remark,
                            lifterCount: res.data.lifterCount,
                            projectMsgUrl: res.data.projectMsgUrl,
                            deviceListUrl: res.data.deviceListUrl,
                            otherAttachUrl: res.data.otherAttachUrl,
                            projectName: res.data.projectName,
                            contractReceivables: (res.data.contractReceivables / 100).toFixed(2),
                            towerCount: res.data.towerCount,
                            updateUserName: res.data.updateUserName,
                            deviceType: res.data.deviceType,
                        }
                        this.contractFile = {
                            id: res.data.id,
                            projectMsgUrl: res.data.projectMsgUrl,
                            otherAttachUrl: res.data.otherAttachUrl,
                            deviceListUrl: res.data.deviceListUrl,
                        }
                        this.isPass = res.data.auditState
                        this.basicTable = res.data.deviceList;
                        this.approvalRecord = res.data.auditList;
                        this.saveTitle = '编辑《' + res.data.contractNum + '》补充协议'
                        var towerItemsStr = res.data.towerMap;
                        if (towerItemsStr) {
                            var funcItem = {};
                            var index = 1
                            for (var i in towerItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.index = index;
                                funcItem.quality = towerItemsStr[i];
                                this.towerItems.push(funcItem);
                                index = index + 1
                            }
                        }
                        var lifterItemsStr = res.data.lifterMap;
                        if (lifterItemsStr) {
                            var funcItem = {};
                            for (var i in lifterItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.quality = lifterItemsStr[i];
                                this.lifterItems.push(funcItem);
                            }
                        }
                        var specialItemsStr = res.data.specialMap;
                        if (specialItemsStr) {
                            var funcItem = {};
                            for (var i in specialItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.quality = specialItemsStr[i];
                                this.specialItems.push(funcItem);
                            }
                        }
                        if (towerItemsStr == null && lifterItemsStr == null) {
                            this.supplementForm.deviceType = 0
                        } else {
                            this.supplementForm.deviceType = 1
                        }
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            cancel(formName) {
                this.supplementForm = {
                    createTime: null,
                    contractName: null,
                    chargePolicy: null,
                    specialPolicy: '',
                    companyName: null,
                    companyType: null,
                    contractNum: "",
                    contractType: 1,
                    id: "",
                    lifterCount: 2,
                    projectName: null,
                    contractReceivables: 0,
                    towerCount: 2,
                    updateUserName: "",
                    other: ''
                }
                this.dialogVisible = false
                this.$refs[formName].resetFields();
            },
            saveSupplement(formName) {
                this.isDisabled = true;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.supplementForm.contractReceivables.split(".").length>2) {
                            this.$message.error('新增账款只能输入一个小数点')
                            this.isDisabled = false;
                            return;
                        }
                        var contractData = JSON.parse(JSON.stringify(this.supplementForm));
                        contractData.contractReceivables = contractData.contractReceivables * 100
                        this.$api.updateSupplement(contractData).then(res => {
                            if (res.code == 200) {
                                this.pageChange("refresh")
                                this.dialogVisible = false
                                this.$message.success(res.message);
                                this.isDisabled = false;
                            } else {
                                this.isDisabled = false;
                                this.$message.error(res.message);
                            }
                        })
                    } else {
                        this.$message.error("请检查数据是否填写完整")
                        this.isDisabled = false
                    }
                })
            },
            pageChange(option) {
                if(option != "refresh"){
                    this.option.pageIndex = option;
                    this.searchItem.pageIndex = option;
                }
                this.$api.pageSupple(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.$api.pageSupple(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            chooseSelection(val) {
                this.multipleSelection = val;
            },

            exportExcel() {
                this.exportDialogVisible = true;
                this.fileName = "补充协议列表";
            },
            searchBtn() {
                this.pageChange(1)
            },
            handleBeforeUpload(file) {
                const typeList = ["xls", "xlsx"];
                const errMsg = '请上传excel文件'
                if (this.flag == 0 || this.flag == 1) {
                    return this.beforeUpload(file, typeList, errMsg)
                }
            },
            beforeUpload(file, arr = [], mssage) {
                const typeList = [...arr];
                const fileName = file.name;
                const extension = fileName.substr(fileName.lastIndexOf(".") + 1);
                const isRT = typeList.includes(extension);
                if (!isRT) {
                    this.$message.error(mssage);
                }
                return isRT;
            },
            uploadList(val) {
                this.url = pathURL + 'erp/file/upload?businessType=list&access_token=' + localStorage.getItem("accessToken")
                this.fileDialog = true
                if (val == 0) {
                    this.uploadTitle = '上传设备型号清单';
                    this.tipMessage = '只能上传excel文件';
                    this.flag = 0
                } else if (val == 1) {
                    this.uploadTitle = '上传项目信息清单'
                    this.tipMessage = '只能上传excel文件';
                    this.flag = 1
                } else {
                    this.uploadTitle = '上传其它附件'
                    this.tipMessage = '可以上传*.xlsx,*.xls,*.doc,*.png等';
                    this.flag = 2
                }
            },
            getSuccessData(res) {
                if (res.code == 200) {
                    if (this.flag == 0) {
                        this.supplementForm.deviceListUrl = res.data.filePath
                        this.contractFile.deviceListUrl = res.data.filePath
                    } else if (this.flag == 1) {
                        this.supplementForm.projectMsgUrl = res.data.filePath
                        this.contractFile.projectMsgUrl = res.data.filePath
                    } else {
                        this.supplementForm.otherAttachUrl = res.data.filePath
                        this.contractFile.otherAttachUrl = res.data.filePath
                    }
                } else {
                    this.$message.error(res.message)
                }
            },
            getErrorData(res) {
                this.$message.error(res.message)
            },
            cancelFile() {
                this.fileDialog = false;
                this.$refs.upload.clearFiles();
            },
            submitFile() {
                this.fileDialog = false;
                this.$refs.upload.clearFiles();
            },
            showSupplement(id) {
                this.towerItems = [];
                this.lifterItems = [];
                this.$api.getSupplementById({id}).then(res => {
                    if (res.code = 200) {
                        this.infoVisible = true;
                        this.supplementDisplay = {
                            createTime: res.data.createTime,
                            chargePolicy: res.data.chargePolicy,
                            parentContractNum: res.data.parentContractNum,
                            companyName: res.data.companyName,
                            companyType: res.data.companyType,
                            contractNum: res.data.contractNum,
                            specialPolicy: res.data.specialPolicy,
                            startDate: res.data.startDate,
                            endDate: res.data.endDate,
                            contractName: res.data.contractName,
                            projectMsgUrl: res.data.projectMsgUrl,
                            deviceListUrl: res.data.deviceListUrl,
                            otherAttachUrl: res.data.otherAttachUrl,
                            isInstalled: res.data.isInstalled,
                            contractType: res.data.contractType,
                            id: res.data.id,
                            deviceType: res.data.deviceType,
                            lifterCount: res.data.lifterCount,
                            projectName: res.data.projectName,
                            contractReceivables: res.data.contractReceivables,
                            towerCount: res.data.towerCount,
                            remark: res.data.remark,
                            updateUserName: res.data.updateUserName,
                        }
                        this.isPass = res.data.auditState
                        this.basicTable = res.data.deviceList;
                        this.showTitle = '《' + res.data.contractNum + '》补充协议'
                        var towerItemsStr = res.data.towerMap;
                        if (towerItemsStr) {
                            var funcItem = {};
                            var index = 1
                            for (var i in towerItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.index = index;
                                funcItem.quality = towerItemsStr[i];
                                this.towerItems.push(funcItem);
                                index = index + 1
                            }
                        }
                        var lifterItemsStr = res.data.lifterMap;
                        if (lifterItemsStr) {
                            var funcItem = {};
                            for (var i in lifterItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.quality = lifterItemsStr[i];
                                this.lifterItems.push(funcItem);
                            }
                        }
                        var specialItemsStr = res.data.specialMap;
                        if (specialItemsStr) {
                            var funcItem = {};
                            for (var i in specialItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.quality = specialItemsStr[i];
                                this.specialItems.push(funcItem);
                            }
                        }
                        this.approvalRecord = res.data.auditList;
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },

            handleCheckAllChange(val) {
                this.supplementForm.demand = val ? this.functions : [];
                this.isIndeterminate = false;
            },
            handleCheckedFunctionsChange(value) {
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.functions.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.functions.length;
            },
        },
        created() {
            this.tableHeight = (window.innerHeight) * 0.67
            this.searchBtn();
            this.$api.getSaleList().then(res => {
                if (res.code == 200){
                    this.saleList = res.data
                }
            })
            this.isSalesman = localStorage.getItem("isSalesman");
            this.jsonFields.map((item, i) => {
                this.json_fields[item.label] = item.prop
            })
            this.json_data = supplement.data;
            this.collectionRecord = amount.data;
            this.basicTable = basicData.data;
            this.shippingData = shipping.data;
            this.functions = funOptions.data;
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    }
</script>

<style lang="scss" scoped>
    .text-title {
        font-size: 16px;
        font-weight: bold;
        color: #437EE6;
        text-align: center;
        margin-bottom: 20px;
    }

    .dialog-body {
        max-height: 650px;
        overflow-y: scroll;
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .iconbianji {
        color: #20a0ff;
        margin-left: 10%;
    }

    .icontongguo {
        color: green;
        margin-left: 10%;
    }

    .iconbohui {
        color: red;
        margin-left: 10%;
    }

    .iconxiangqing {
        margin-left: 1%;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 17%;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px 10px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .handle-input-date {
        width: 150px;
        display: inline-block;
        padding-right: 0px;
    }

    .information {
        background-color: #F0F2F5;
    }

    .dialog-title {
        margin: 0 20px;
        color: #67ADD9;
        font-size: 20px;
        /*border: 1px red solid;*/
    }

    .payment-table, .approval-table {
        margin-top: 10px;
    }


    .text-title {
        font-size: 16px;
        font-weight: bold;
        color: #437EE6;
        text-align: center;
        margin-bottom: 20px;
    }

    .dialog-body {
        max-height: 650px;
        overflow-y: scroll;
    }

    .td-body {
        padding: 10px 0;
        white-space: nowrap;
        overflow-x: scroll;
        width: 1100px;
    }

    td {
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .upload-files {
        margin-top: 50px;
    }

    .file-type {
        margin-top: 10px;
        width: 100%;
        display: flex;
    }

    .td-text {
        width: 90px;
        text-align: right;
    }

    .td-input {
        width: 28%;
    }
</style>
